<template>
  <div>
    <div>
      <a
        class="twitter-timeline"
        href="https://twitter.com/QuestoesP?ref_src=twsrc%5Etfw"
        >Tweets por Questões PRO</a
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "TwitterTimeline",

  mounted() {
    window.twttr.widgets.load(this.$el);
  },
};
</script>
