<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="mb-3">
          <i class="fas fa-handshake mr-1"></i> Indicar amigos
        </h4>
        <h5 class="mb-4">
          Chame seus amigos para estudar com você e ganhe
          <span class="text-info">
            20% do valor da primeira assinatura deles
          </span>
        </h5>

        <h5 class="mb-3">
          Compartilhe seu código promocional nas redes sociais e com amigos.
        </h5>

        <b-input-group class="mb-4" prepend="Link">
          <b-form-input v-model="referralLink" disabled=""></b-form-input>
          <b-input-group-append>
            <b-button @click.prevent="copy" text="Button" variant="info">
              Copiar
            </b-button>
          </b-input-group-append>
        </b-input-group>

        <div class="row">
          <div class="col-6 text-center">
            <h5>Saldo</h5>
            <h3>{{amount}}</h3>
            <a v-if="amount > 0" href="" @click.prevent="">Retirar</a>
          </div>

          <div class="col-6 text-center">
            <h5>Amigos</h5>
            <h3>
              <i class="mdi mdi-account-multiple"></i>
              <span class="mx-1">{{ referrals.length }}</span>
            </h3>
            <h5>
              <a href="#" @click.prevent="" v-b-modal.referralFriends>
                Ver
              </a>
            </h5>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="referralFriends" scrollable title="Amigos indicados" ok-only>
      <b-media class="my-3" v-for="referral in referrals" :key="referral.id">
        <template v-slot:aside>
          <b-img
            :src="
              referral.user.avatar
                ? $getImageUrl(referral.user.avatar.originalName)
                : $defaultUserAvatar
            "
            rounded="circle"
            height="64"
            alt="Generic placeholder image"
          ></b-img>
        </template>

        <h5 class="mt-0">
          <router-link
            :to="{
              name: 'profile',
              params: { username: referral.user.username },
            }"
          >
            {{ referral.user | name }}
          </router-link>
        </h5>
        <h6>
          <b-badge pill :variant="referral.rewarded ? 'success' : 'warning'">
            {{ referral.rewarded ? "Recompensado" : "Pendente" }}
          </b-badge>
        </h6>
      </b-media>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    referrals: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      amount: 0
    };
  },

  mounted() {
    this.amount = this.currentUser.referral.map(referer => referer.amount).reduce((previousAmount, amount) => {
      return parseFloat(previousAmount) + parseFloat(amount)
    }, 0)
  },

  computed: {
    ...mapState("auth", ["currentUser"]),

    referralLink() {
      const params = new URLSearchParams({referer: this.currentUser.username})
      return `${window.location.origin}/?${params}`;
    },
  },

  methods: {
    copy() {
      copyToClipboard(this.referralLink);
      this.$swal("Copiado!", "Cole o link para seus amigos", "success");
    },
  },
};

function copyToClipboard(str) {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}
</script>
