var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"page-title-box"},[_c('h4',[_vm._v("Página inicial")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-6 col-12"},[(_vm.homePage)?_c('div',[_c('Referral',{attrs:{"referrals":_vm.homePage.referrals}})],1):_vm._e()]),_c('div',{staticClass:"col-lg-6 col-md-6 col-12"},[_c('div',{staticClass:"card card-checkin"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"mb-3"},[_c('i',{staticClass:"fas fa-map-marked-alt mr-1"}),_vm._v(" Fazer check-in ")]),_c('h5',{staticClass:"mb-4"},[_vm._v(" Compartilhe em suas redes sociais com os seus amigos que você está utilizando o QuestõesPRO! ")]),_c('div',{staticClass:"mb-4"},_vm._l(([
                'facebook',
                'twitter',
                'whatsapp',
                'telegram',
              ]),function(network){return _c('ShareNetwork',{key:network,attrs:{"network":network,"url":_vm.socialShare.url,"title":_vm.socialShare.title,"description":_vm.socialShare.description,"quote":_vm.socialShare.quote,"hashtags":_vm.socialShare.hashtags}},[_c('b-button',{staticClass:"mr-1",attrs:{"pill":"","variant":"dark","size":"lg"}},[_c('i',{class:`mdi mdi-${network} mdi-24px`})])],1)}),1)])])]),_c('div',{staticClass:"col-lg-6 col-md-6 col-12"},[(_vm.interestStats)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"mb-3"},[_vm._v("Oportunidades de análise")]),_c('div',{staticClass:"table-responsive mb-0"},[_c('table',{staticClass:"table table-hover table-centered table-nowrap"},[_c('thead',[_c('tr',[_c('th',{staticClass:"interest-name-col",attrs:{"scope":"col"}},[_vm._v("Interesse")]),_c('th',{attrs:{"scope":"col"}},[_vm._v(" Questões"),_c('br'),_vm._v(" pendentes"),_c('br'),_vm._v(" de análise ")])])]),_c('tbody',_vm._l((_vm.interestStats),function(stat){return _c('tr',{key:stat.id},[_c('td',{staticClass:"interest-name-col"},[_vm._v(" "+_vm._s(stat.name)+" ")]),_c('td',[_vm._v(_vm._s(stat.questions - stat.reviewCount))]),_c('td',[_c('div',[_c('router-link',{staticClass:"btn btn-primary btn-sm",attrs:{"to":{
                          name: 'questions',
                          query: { interestId: stat.id },
                        }}},[_vm._v(" Ver ")])],1)])])}),0)])])])]):_vm._e()]),_c('div',{staticClass:"col-lg-6 col-md-6 col-12"},[_c('div',{staticClass:"card",staticStyle:{"display":"none"}},[_c('div',{staticClass:"card-body"},[_c('TwitterTimeline')],1)])])]),_c('div',{staticClass:"row"},[_c('b-modal',{attrs:{"id":"instagram-modal","size":"xl","title":"Compartilhar","body-class":"text-center","ok-only":""}},[_c('ShareableImage',{attrs:{"background":"default"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }