var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_vm._m(0),_vm._m(1),_c('h5',{staticClass:"mb-3"},[_vm._v(" Compartilhe seu código promocional nas redes sociais e com amigos. ")]),_c('b-input-group',{staticClass:"mb-4",attrs:{"prepend":"Link"}},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.referralLink),callback:function ($$v) {_vm.referralLink=$$v},expression:"referralLink"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"text":"Button","variant":"info"},on:{"click":function($event){$event.preventDefault();return _vm.copy.apply(null, arguments)}}},[_vm._v(" Copiar ")])],1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 text-center"},[_c('h5',[_vm._v("Saldo")]),_c('h3',[_vm._v(_vm._s(_vm.amount))]),(_vm.amount > 0)?_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();}}},[_vm._v("Retirar")]):_vm._e()]),_c('div',{staticClass:"col-6 text-center"},[_c('h5',[_vm._v("Amigos")]),_c('h3',[_c('i',{staticClass:"mdi mdi-account-multiple"}),_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(_vm.referrals.length))])]),_c('h5',[_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.referralFriends",modifiers:{"referralFriends":true}}],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_vm._v(" Ver ")])])])])],1)]),_c('b-modal',{attrs:{"id":"referralFriends","scrollable":"","title":"Amigos indicados","ok-only":""}},_vm._l((_vm.referrals),function(referral){return _c('b-media',{key:referral.id,staticClass:"my-3",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-img',{attrs:{"src":referral.user.avatar
              ? _vm.$getImageUrl(referral.user.avatar.originalName)
              : _vm.$defaultUserAvatar,"rounded":"circle","height":"64","alt":"Generic placeholder image"}})]},proxy:true}],null,true)},[_c('h5',{staticClass:"mt-0"},[_c('router-link',{attrs:{"to":{
            name: 'profile',
            params: { username: referral.user.username },
          }}},[_vm._v(" "+_vm._s(_vm._f("name")(referral.user))+" ")])],1),_c('h6',[_c('b-badge',{attrs:{"pill":"","variant":referral.rewarded ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(referral.rewarded ? "Recompensado" : "Pendente")+" ")])],1)])}),1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"mb-3"},[_c('i',{staticClass:"fas fa-handshake mr-1"}),_vm._v(" Indicar amigos ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"mb-4"},[_vm._v(" Chame seus amigos para estudar com você e ganhe "),_c('span',{staticClass:"text-info"},[_vm._v(" 20% do valor da primeira assinatura deles ")])])
}]

export { render, staticRenderFns }